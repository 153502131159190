<template>
  <v-data-table
    :headers="headers"
    :items="events"
    :items-per-page="15"
    :item-class="rowClass"
    :loading="loading"
    v-bind="$attrs"
    class="elevation-1"
  >
    <template v-slot:item.costs="{ item }">
      <v-icon dense small v-if="isPaid(item)">
        mdi-currency-usd
      </v-icon>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon small v-if="!item.closed" @click="closeItem(item)">
        mdi-close
      </v-icon>
    </template>
    <template v-slot:item.code.category="{}">
      <v-icon>
        mdi-bell
      </v-icon>
    </template>
    <template v-slot:item.code.text="{ item }">
      <router-link :to="{ name: 'Event', params: { id: item.id } }">{{
        item.code.text
      }}</router-link>
    </template>
    <template v-slot:item.created_at="{ value }">
      {{ mediumDataTime(value) }}
    </template>
    <template v-slot:item.tasks="{ item }">
      <v-avatar
        v-for="(task, i) in item.tasks"
        :key="i"
        class="ma-2"
        :class="avatarClass(task)"
      >
        <!-- <v-img src="https://secure.gravatar.com/avatar/352e458a5df2ec9357c056ebe8d863fc?s=192&d=identicon"/> -->
        <task-icon normal :task="task.task_type"/>
      </v-avatar>
    </template>
    ></v-data-table
  >
</template>

<script>
// import EventService from '@/services/event.service'
import { parseJSON } from 'date-fns'

export default {
  name: 'EventsTable',

  beforeMount() {
    // console.log('fetching')
    // EventService.fetchSiteEvents(1318).then(response => {
    //   console.log("Response", response.data)
    //   this.events = response.data
    // });
    // // this.events = []
    // this.loading = false
  },
  props: {
    events: Array,
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    headers: [
      { text: '', align: 'center', value: 'costs', width: '1px' },
      { text: 'Data', align: 'start', value: 'created_at' },
      { text: 'Typ', value: 'code.category' },
      { text: 'Zadanie', value: 'code.text' },
      // { text: 'Actions', value: 'actions', align: 'center', sortable: false },
      { text: 'Tasks', value: 'tasks', align: 'lef', sortable: false },
      { text: '', value: 'icon' },
    ],
  }),
  computed: {},
  funtions: {},
  methods: {
    mediumDataTime(value) {
      return parseJSON(value).toLocaleString(window.navigator.language, {
        timeStyle: 'medium',
        dateStyle: 'medium',
      })
    },
    rowClass(item) {
      for (const task of item.tasks) {
        if (task.closed_at == null) {
          return 'red lighten-4'
        }
      }
      return ''
    },
    avatarClass(task) {
      if (task.closed_at == null) {
        return 'yellow lighten-1'
      } else {
        return 'blue lighten-1'
      }
    },
    isPaid(item) {
      for (const task of item.tasks) {
        if (task.details.cost != null) {
          return true
        }
      }
      return false
    },
    demoEvents() {
      return [
        {
          id: 19,
          code: {
            id: 1,
            code: '100.1',
            category: '1.0',
            text: 'Medical support needed',
          },
          site: {
            code_name: 'E204',
            name: 'JANISZEWSKI dom',
            address: 'ul. Krośniąt 26b/2',
          },
          created_at: '2021-06-01T10:14:17.719156',
          details: {
            date: 1620900780000,
          },
          tasks: [
            {
              id: 1,
              created_by: 'operator_1',
              costs: [{}],
              assignees: [
                {
                  group_id: null,
                  user_id: 2,
                  user: '101',
                },
              ],
              task_type: 1,
              description: '101',
              created_at: '2021-06-04T10:29:10.820379',
              closed_at: '2021-06-25T20:41:54.094988',
              details: {},
              modified_at: '2021-06-25T20:41:54.094988',
              closed_by: null,
              parent: null,
              modified_by: null,
            },
            {
              id: 2,
              created_by: 'operator_1',
              costs: [],
              assignees: [
                {
                  group_id: null,
                  user_id: 2,
                  user: '103',
                },
              ],
              task_type: 2,
              description: 'Sprawdzić obiekt',
              created_at: '2021-06-04T10:32:36.335654',
              closed_at: null,
              details: {},
              modified_at: '2021-06-25T20:41:54.094988',
              closed_by: null,
              parent: 1,
              modified_by: null,
            },
          ],
        },
        {
          id: 20,
          code: {
            id: 1,
            code: '100.1',
            category: '1.0',
            text: 'Medical support needed - zakonczony',
          },
          site: {
            code_name: 'E204',
            name: 'JANISZEWSKI dom',
            address: 'ul. Krośniąt 26b/2',
          },
          created_at: '2021-06-01T10:14:17.719156',
          details: {
            date: 1620900780000,
          },
          tasks: [
            {
              id: 1,
              created_by: 'operator_1',
              costs: [{}],
              assignees: [
                {
                  group_id: null,
                  user_id: 2,
                  user: '101',
                },
              ],
              task_type: 1,
              description: '101',
              created_at: '2021-06-04T10:29:10.820379',
              closed_at: '2021-06-25T20:41:54.094988',
              details: {},
              modified_at: '2021-06-25T20:41:54.094988',
              closed_by: null,
              parent: null,
              modified_by: null,
            },
          ],
        },
        {
          id: 31,
          code: {
            id: 1,
            code: '100.1',
            category: '1.0',
            text: 'Medical support needed',
          },
          site: {
            code_name: 'E204',
            name: 'JANISZEWSKI dom',
            address: 'ul. Krośniąt 26b/2',
          },
          created_at: '2021-06-01T10:14:17.719156',
          details: {
            date: 1620900780000,
          },
          tasks: [
            {
              id: 1,
              created_by: 'operator_1',
              costs: [{}],
              assignees: [
                {
                  group_id: null,
                  user_id: 2,
                  user: '101',
                },
              ],
              task_type: 1,
              description: '101',
              created_at: '2021-06-04T10:29:10.820379',
              closed_at: null,
              details: {},
              modified_at: '2021-06-25T20:41:54.094988',
              closed_by: null,
              parent: null,
              modified_by: null,
            },
          ],
        },
      ]
    },
  },
}
</script>
