<template>
  <page-content>
    <v-toolbar flat v-if="tabs.length > 1">
      <app-tabs v-model="selectedTab">
        <v-tab v-for="tab of tabs" :key="tab.id" :to="'#' + tab.id">{{
          tab.name
        }}</v-tab>
      </app-tabs>
      <create-btn icon="mdi-bell-plus" text="Utwórz" tooltip="Nowe zdarzenie" @click="onNewEvent"></create-btn>
      <toolbar-btn v-if="count > 0" icon="mdi-cloud-download-outline" tooltip="Zapis do pliku" @click="onDownload()" />
    </v-toolbar>
    <v-divider></v-divider>
    <app-toolbar justify="end">
      <v-col>
        <app-filter-field label="" v-model="filter" @input="onFilter"></app-filter-field>
      </v-col>
      <v-col cols="12" md="3">
        <v-select outlined dense return-object hide-details v-model="categorySelected" :items="categories"
          :item-text="(item) => item.name" label="Kategoria">
        </v-select>
      </v-col>
      <v-col cols="12" md="3">
        <date-range-editor :items="ranges" v-model="rangeSelected"></date-range-editor>
      </v-col>
      <v-col cols="12" md="3" v-if="sortBy.length > 1">
        <app-sort-select v-model="sortBySelected" :items="sortBy" :item-text="(item) => item.name"></app-sort-select>
      </v-col>
    </app-toolbar>

    <v-divider></v-divider>
    <v-card-subtitle>
      <v-row dense align="center">
        <v-col cols="3" md="2">Data</v-col>
        <v-col cols="7" md="7">Zdarzenie<v-chip v-if="count > 0" small class="ml-3">{{ count }}</v-chip></v-col>
        <v-col cols="2" md="3">Interwencje</v-col>
      </v-row>
    </v-card-subtitle>
    <v-divider class="mb-2"></v-divider>
    <div v-for="event of events" :key="event.id">
      <event-row :siteHidden="siteHidden" :event="event"></event-row>
    </div>
    <v-card-actions class="justify-center">
      <v-pagination v-if="pages > 1" v-model="page" :length="pages" circle></v-pagination>
    </v-card-actions>
  </page-content>
</template>

<script>

import EventService from "@/services/event.service"
import { Labels } from "@/store/modules/task"

export default {
  name: "SiteEvents",
  data() {
    return {
      timer: null,
      categorySelected: this.categories[0],
      selectedTab: null,
      sortBySelected: null, // TaskService.getTaskOrdering()[0],
      rangeSelected: null, //TaskService.getTaskRanges()[0],
      filter: "",
      page: 1,
      created: false
    }
  },
  props: {
    tabs: {
      type: Array,
      default: () => EventService.getMainConditions()
    },
    categories: {
      type: Array,
      default: () => EventService.getCategoryConditions()
    },
    sortBy: {
      type: Array,
      default: () => EventService.getEventOrdering()
    },
    ranges: {
      type: Array,
      default: () => EventService.getEventRanges()
    },
    pages: {
      type: Number,
      default: () => 0
    },
    count: {
      type: Number,
      default: () => 0
    },
    extraConditions: {
      type: Array,
      default: () => []
    },
    events: {
      type: Array,
      default: () => []
    },
    siteHidden: {
      type: Boolean,
      default: null
    }
  },
  watch: {
  },
  computed: {},
  methods: {
    onFilter() {
      this.refresh()
    },
    onNewEvent() {
      this.$router.push({
        name: "New Event"
      })
    },
    onDownload() {
      this.$emit("download-request", this.createRefreshContext())
    },
    createRefreshContext() {
      var range = { range: this.rangeSelected.id }
      if (this.rangeSelected.id == 'custom') {
        range = {
          range: this.rangeSelected.id,
          from: this.rangeSelected.from,
          to: this.rangeSelected.to
        }
      }
      var query = {
        ...this.tabs.find(t => t.id == this.selectedTab)?.params,
        ...range,
        ...this.categorySelected.params,
        page: `${this.page}`,
        ordering: this.sortBySelected.ordering
      }
      if (this.filter) {
        query.search = this.filter
      }

      if (JSON.stringify(query) != JSON.stringify(this.$route.query)) {
        this.$router.replace({
          path: this.$route.path,
          query: query,
          hash: this.$route.hash
        })
      }
      return {
        page: this.page,
        query: query,
        filter: this.filter,
        category: this.categorySelected,
        range: this.rangeSelected,
        ordering: this.sortBySelected?.ordering
      }
    },
    refresh() {
      this.$emit("refresh-request", this.createRefreshContext())
    },
    icon(status) {
      return Labels.get(status.label_id).icon
    },
    color(status) {
      const x = `${Labels.get(status.label_id).color}`.split(" ")
      return `${x[0]}--text text--${x[1]}`
    }
  },
  beforeMount() {

    const query = this.$route.query
    this.filter = query.search
    this.categorySelected = this.categories.find(c => c.id == query.category) ?? this.categories[0]
    this.sortBySelected = this.sortBy.find(c => c.ordering == query.ordering) ?? this.sortBy[0]
    var range = this.ranges[0]
    if (query.ranges) {
      range = this.ranges.find(c => c.id == query.range) ?? this.ranges[0]
    } else if (query.from) {
      range = this.ranges.find(c => c.id == "custom") ?? this.ranges[0]
    }

    if (range.id == "custom") {
      range = {
        ...range,
        from: query.from,
        to: query.to
      }
    }
    this.rangeSelected = range

    const watches = ['page', 'sortBySelected', 'rangeSelected', 'categorySelected', 'filter', 'selectedTab']
    watches.forEach((watch) => {
      this.$watch(watch, () => {
        this.refresh()
      })
    })
    this.selectedTab = this.tabs.find(c => c.id == query.state)?.id ?? this.tabs[0].id //state=open as default
    this.timer = setInterval(this.refresh, 10000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>
