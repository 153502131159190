export const CategoryId = {
  ALL: 0,
  ALARM: '1.0',
  STATUS: '2.0',
  TROUBLE: '4.0',
  END_OF_TROUBLE: '5.0',
  TEST: '6.0',
  WARNING: '7.0',
  OTHER: '8.0',
  SERVICE: '9.0',
  EDIT: '10.0',
};
export class Events {}

export class Categories {
  static get(id) {
    return this.all().find((item) => item.id == id);
  }

  static all() {
    return [
      { id: CategoryId.ALL, name: 'Wszystkie', color: 'teal' },
      { id: CategoryId.ALARM, name: 'Alarm', color: 'red' },
      { id: CategoryId.TROUBLE, name: 'Awaria', color: 'orange' },
      { id: CategoryId.END_OF_TROUBLE, name: 'Koniec awarii', color: 'green' },
      { id: CategoryId.TEST, name: 'Test', color: 'olive' },
      { id: CategoryId.WARNING, name: 'Uwaga', color: 'pink' },
      { id: CategoryId.STATUS, name: 'Status', color: 'blue' },
      { id: CategoryId.SERVICE, name: 'Serwis', color: 'green' },
      { id: CategoryId.EDIT, name: 'Edycja bazy', color: 'purple' },
      { id: CategoryId.OTHER, name: 'Inne', color: 'grey' },
    ];
  }
}

export default new Events();
